import Form from 'react-bootstrap/Form';
import {FormGroup} from "react-bootstrap";
interface Props {
    name: string;
    label: string;
    value: string;
    placeholder?: string;
    disabled?: boolean;
    onChange?(): void;
}
export default function FormTextArea(props: Props ) {
    return (
        <FormGroup>
            <Form.Label className="label">{props.label}</Form.Label>
            <Form.Control as="textarea" name={props.name} value={props.value} placeholder={props.placeholder} disabled={props.disabled} onChange={props.onChange}/>
        </FormGroup>
    );
};
