// Prezzi tariffa Macellazione
import FormText from "../../components/FormText";
import FormTextArea from "../../components/FormTextArea";

export const tariffeMacellazione = {
    1: 16.54,
    2: 28.08,
    3: 39.62,
    4: 51.16,
} as const;

export const formMail = (formikProps: any) => (
    <FormText
        name="email"
        value={formikProps.email}
        onChange={formikProps.handleChange}
        error={formikProps.errors["email"]}
        type="text"
        label="Email"
        placeholder="Inserisci qui"
    />
);

export const formTelefono = (formikProps: any) => (
    <FormText
        name="telefono"
        value={formikProps.telefono}
        onChange={formikProps.handleChange}
        error={formikProps.errors["telefono"]}
        type="number"
        label="Telefono"
        placeholder="Inserisci qui"
    />
);

export const formAnimaliPerPrenotazione = (formikProps: any, tipologia: string) => (
    <FormText
        name="animali_per_prenotazione"
        value={formikProps.values.animali_per_prenotazione}
        onChange={formikProps.handleChange}
        error={formikProps.errors["animali_per_prenotazione"]}
        type="number"
        label={"Numero animali per prenotazione"}
        note={tipologia == "25" ? "Il numero massimo di animali consentiti è di 4 maiali all’anno" : ""}
        max={tipologia == "25" ? 4 : ""}
        min={1}
        placeholder="Inserisci qui"
    />
);

export const formNotes = (formikProps: any) => (
    <FormTextArea
        name="notes"
        value={formikProps.notes}
        onChange={formikProps.handleChange}
        label="Note"
        placeholder="Inserisci qui"
    />
);
