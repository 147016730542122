import React, {useEffect, useRef} from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

interface SliderComponentProps {
    slideShow: number;
    slidesToScroll: number;
    slickGoToIndex: number;
    children: React.ReactNode;
}

const SliderComponent: React.FC<SliderComponentProps> = (props) => {

    const sliderRef = useRef<Slider | null>(null);

    useEffect(() => {
        // Check if slickGoToIndex exists and sliderRef is not null before calling slickGoTo
        if (props.slickGoToIndex !== undefined && sliderRef.current) {
            sliderRef.current.slickGoTo(props.slickGoToIndex);
        }
    }, [props.slickGoToIndex]);

    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: props.slideShow,
        slidesToScroll: props.slideShow,
        initialSlide: 0,
        adaptiveHeight: false,
        centerMode: false,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: props.slideShow,
                    slidesToScroll: props.slideShow,
                    infinite: false,
                    dots: false
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ],
    };

    return (
        <div className="slider-container">
            <Slider
                ref={sliderRef}
                {...settings}
            >
                {props.children}
            </Slider>
        </div>
    );
};

export default SliderComponent;
