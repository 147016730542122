import React from "react";
import Form from "react-bootstrap/Form";
import {Formik} from "formik";
import {Button, FormGroup} from "react-bootstrap";
import 'leaflet/dist/leaflet.css';
import 'leaflet/dist/leaflet.css';

interface Props {
    formik: any;
}

const AnimalsType: React.FC<Props> = (props: Props) => {

    const containerActionsStyles = {
        marginTop: "40px"
    };

    const actionsStyles = {
        display: "flex",
        flexDirection: "row" as const,
        alignItems: "center",
        justifyContent: "end",
        gap: "15px",
    };

    const radioButtonStyles = {
        padding: "20px 50px",
        backgroundColor: "#F5F5F5",
        border: "2px solid #F5F5F5",
        transition: "all 0.3s",

    };

    const radioButtonActionStyles = {
        border: "2px solid #01762F",
    }

    return (
        <Formik initialValues={props.formik.initialValues}  onSubmit={props.formik.handleSubmit}>
            <Form onSubmit={props.formik.handleSubmit}>
                <div className="p-4 rounded-lg mb-100">
                    <h2 className="my-3">
                        Tipologia animale
                    </h2>

                    <FormGroup>
                        <div className="d-flex flex-column">
                            <Form.Label className="label sr-only">
                                Tipologia
                            </Form.Label>

                            <div className="d-flex flex-md-row flex-column bg-white p-3 rounded-2 gap-3">
                                <div className="form-check flex-fill rounded-2"
                                     style={{
                                         ...radioButtonStyles,
                                         ...(props.formik.values.tipologia_animali === "animali_da_affezione" ? radioButtonActionStyles : {})
                                     }}>
                                    <input
                                        className="form-check-input cursor-pointer"
                                        type="radio"
                                        name="tipologia_animali"
                                        value="animali_da_affezione"
                                        id={`tipologia_animali-animali_da_affezione`}
                                        checked={props.formik.values.tipologia_animali === "animali_da_affezione"}
                                        onChange={props.formik.handleChange}
                                    />
                                    <label className="form-check-label" htmlFor="tipologia_animali-animali_da_affezione">
                                        Animali da affezione
                                    </label>
                                </div>

                                <div className="form-check flex-fill rounded-2" style={{
                                    ...radioButtonStyles,
                                    ...(props.formik.values.tipologia_animali === "animali_da_reddito" ? radioButtonActionStyles : {})
                                }}>
                                    <input
                                        className="form-check-input cursor-pointer"
                                        type="radio"
                                        name="tipologia_animali"
                                        value="animali_da_reddito"
                                        id={`tipologia_animali-animali_da_reddito`}
                                        checked={props.formik.values.tipologia_animali === "animali_da_reddito"}
                                        onChange={props.formik.handleChange}
                                    />
                                    <label className="form-check-label" htmlFor="tipologia_animali-animali_da_reddito">
                                        Animali da reddito
                                    </label>
                                </div>

                            </div>
                        </div>
                    </FormGroup>

                    <div style={containerActionsStyles}>
                        <hr/>
                        <div style={actionsStyles}>
                            <div>
                                <Button variant="primary" type="submit" disabled={!props.formik.isValid || !props.formik.dirty}>Prossimo</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </Form>
        </Formik>
    );
};

export default AnimalsType;
