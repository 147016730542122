import React, {useEffect, useState} from "react";
import {Formik} from "formik";
import Form from "react-bootstrap/Form";
import FormUpload from "./FormUpload";
import {Button} from "react-bootstrap";
import FormSwitch from "./FormSwitch";
import {Option} from "../assets/interface/interface";
import {useGetPrestazioniQuery} from "../services/apiSlice";
import FormTextArea from "./FormTextArea";
import {formNotes, tariffeMacellazione} from "../assets/utilities/variables";

interface Props {
    formik: any;
    typeBooking: Option;
    animali_per_prenotazione: number;
    tipologia_animali: string;
    goToBackTab(): void;
}

const Documents = (props: Props) => {
    const { data: prestazioniData, error: prestazioniError } = useGetPrestazioniQuery({
        reddito: props.tipologia_animali === "animali_da_reddito",
        tipo: "sportello"
    });

    const tariffe = prestazioniData && prestazioniData.find(pres => pres.id === props.typeBooking.value)?.tariffe || [];
    const totaleTariffa = tariffe.reduce((acc, trf) => acc + parseFloat(trf.prezzo), 0);
    const roundedTotal = totaleTariffa.toFixed(2);

    const [summary, setSummary] = useState(0);

    useEffect(() => {

        if (props.typeBooking.value === "25") {
            if (props.animali_per_prenotazione == 1) {
                setSummary(tariffeMacellazione[1]);
            } else if (props.animali_per_prenotazione == 2) {
                setSummary(tariffeMacellazione[2]);
            } else if (props.animali_per_prenotazione == 3) {
                setSummary(tariffeMacellazione[3]);
            } else if (props.animali_per_prenotazione == 4) {
                setSummary(tariffeMacellazione[4]);
            }
        } else {
            setSummary(props.animali_per_prenotazione * roundedTotal);
        }

    }, [props.animali_per_prenotazione]);

    const containerActionsStyles = {
        marginTop: "40px"
    };

    const actionsStyles = {
        display: "flex",
        flexDirection: "row" as const,
        alignItems: "center",
        justifyContent: "end",
        gap: "15px",
    };

    const cancel = () => {
        props.goToBackTab();
    };

    return (
        <Formik initialValues={props.formik.initialValues}  onSubmit={props.formik.handleSubmit}>
            <Form onSubmit={props.formik.handleSubmit}>
                <div className="p-4 rounded-lg mb-100">

                    {props.tipologia_animali === "animali_da_affezione" && <>
                        <h2 className="my-3">
                            Documenti
                        </h2>

                        <div className="flex" style={{columns: "240px 2"}}>
                            {(props.typeBooking.value === "1" || props.typeBooking.value === "3") &&
                                <div className="my-2" style={{flex: "100", display: "inline-block", width: "100%"}}>
                                    <FormUpload formik={props.formik} name="certificato_vaccinazione_antirabbica"
                                                value={props.formik.values.certificato_vaccinazione_antirabbica}
                                                error={props.formik.errors["certificato_vaccinazione_antirabbica"]}
                                                type="file"
                                                label={"Certificato veterinario di vaccinazione antirabbica (1)" + (props.typeBooking.value === "1" ? " (facoltativo)" : "")}></FormUpload>
                                </div>}

                            {(props.typeBooking.value === "1" || props.typeBooking.value === "3") &&
                                <div className="my-2" style={{flex: "100", display: "inline-block", width: "100%"}}>
                                    <FormUpload formik={props.formik} name="certificato_iscrizione_anagrafe"
                                                value={props.formik.values.certificato_iscrizione_anagrafe}
                                                error={props.formik.errors["certificato_iscrizione_anagrafe"]}
                                                type="file"
                                                label="Certificato di iscrizione All’anagrafe Animali d’Affezione Regionale (1)"></FormUpload>
                                </div>
                            }

                            {(props.typeBooking.value === "3" || props.typeBooking.value === "1") &&
                                <div className="my-2" style={{flex: "100", display: "inline-block", width: "100%"}}>
                                    <FormUpload formik={props.formik} name="certificazione_microchip"
                                                value={props.formik.values.certificazione_microchip}
                                                error={props.formik.errors.certificazione_microchip}
                                                type="file"
                                                label="Certificato vet. di identificazione e leggibilità del microchip (1)"></FormUpload>
                                </div>
                            }

                            {props.typeBooking.value === "2" &&
                                <div className="my-2" style={{flex: "100", display: "inline-block", width: "100%"}}>
                                    <FormUpload formik={props.formik} name="documento_cessione_cane"
                                                value={props.formik.values.documento_cessione_cane}
                                                error={props.formik.errors.documento_cessione_cane}
                                                type="file" label="Dichiarazione cessione animale"></FormUpload>
                                </div>
                            }

                            {props.typeBooking.value === "2" &&
                                <div className="my-2" style={{flex: "100", display: "inline-block", width: "100%"}}>
                                    <FormUpload formik={props.formik} name="documento_cedente_acquirente"
                                                value={props.formik.values.documento_cedente_acquirente}
                                                error={props.formik.errors.documento_cedente_acquirente}
                                                type="file"
                                                label="Documento d’identità del cedente o acquirente"></FormUpload>
                                </div>
                            }

                            {(props.typeBooking.value === "1") &&
                                <div className="my-2" style={{flex: "100", display: "inline-block", width: "100%"}}>
                                    <FormUpload formik={props.formik} name="documento_richiesta_passaporto"
                                                value={props.formik.values.documento_richiesta_passaporto}
                                                error={props.formik.errors.documento_richiesta_passaporto}
                                                type="file" label="Richiesta passaporto (1)"></FormUpload>
                                </div>}

                            {(props.typeBooking.value === "3") &&
                                <div className="my-2" style={{flex: "100", display: "inline-block", width: "100%"}}>
                                    <FormUpload formik={props.formik} name="certificato_espatrio"
                                                value={props.formik.values.certificato_espatrio}
                                                error={props.formik.errors.certificato_espatrio}
                                                type="file" label="Certificato Espatrio (1)"></FormUpload>
                                </div>}

                            {(props.typeBooking.value === "1" || props.typeBooking.value === "3") &&
                                <div className="my-2" style={{flex: "100", display: "inline-block", width: "100%"}}>
                                    <FormUpload formik={props.formik} name="eventuali_documenti"
                                                value={props.formik.values.eventuali_documenti}
                                                error={props.formik.errors.eventuali_documenti}
                                                type="file"
                                                label="Eventuali documentazione richiesta dal paese ricevente (1)"></FormUpload>
                                </div>}
                        </div>

                        <hr/>

                        <div className="row">
                            <div className="col-md-6 my-2 mt-3">
                                <FormSwitch
                                    name="delega"
                                    value={props.formik.values.delega}
                                    onChange={props.formik.handleChange}
                                    id="delega"
                                    label="Delega"
                                />
                            </div>
                            {
                                props.formik.values.delega && <div className="col-md-6 my-2">
                                    <FormUpload formik={props.formik} name="documento_delega"
                                                value={props.formik.values.documento_delega}
                                                error={props.formik.errors.documento_delega}
                                                type="file" label="Documento di delega"></FormUpload>
                                </div>
                            }

                        </div>

                        <hr className="mb-5"/>
                    </>}


                    <h2 className="my-3">
                        Informativa privacy ai sensi del Regolamento 679/2016/UE
                    </h2>


                    <div className="row">
                        <div className="col-md-6 mb-2 mt-3">
                            <FormSwitch
                                name="privacy"
                                value={props.formik.values.privacy}
                                onChange={props.formik.handleChange}
                                id="privacy"
                                label="Accetto Informativa privacy"
                            />
                        </div>
                    </div>

                    <p className="mt-4 mb-5 fst-italic" style={{fontSize: "15px"}}>
                        Si comunica che tutti i dati personali (comuni identificativi, particolari e/o giudiziari)
                        comunicati a <b>ATS Insubria</b> saranno trattati esclusivamente per finalità istituzionali nel
                        rispetto delle prescrizioni previste <b>Regolamento 679/2016/UE</b>.
                        Il trattamento dei dati personali avviene utilizzando strumenti e supporti sia cartacei che
                        informatici.Il Titolare del trattamento dei dati personali è <b>ATS Insubria</b>.
                        L’Interessato può esercitare i diritti previsti dagli articoli 15, 16, 17, 18, 20, 21 e 22
                        del <b>Regolamento 679/2016/UE</b>.
                        <br/>
                        L’informativa completa redatta ai sensi degli articoli 13 e 14 del <b>Regolamento
                        679/2016/UE</b> è reperibile presso gli uffici aziendali e consultabile sulla homepage del sito
                        web dell’Ente – sezione privacy - all'indirizzo <a href="https://www.ats-insubria.it/privacy"
                                                                           target="_blank">https://www.ats-insubria.it/privacy</a>.
                        I dati di contatto del Data Protection Officer/Responsabile della Protezione dei dati
                        individuato dal Titolare sono reperibili sul sito istituzionale dell’Ente.
                    </p>

                    {(props.tipologia_animali === "animali_da_affezione" || (props.tipologia_animali === "animali_da_reddito" && props.typeBooking.value === "25")) && <>
                        <hr/>

                        <h2 className="my-3 mt-5">
                            Pagamento
                        </h2>

                        <p>
                            Il prezzo totale della prestazione
                            per {props.animali_per_prenotazione} {props.animali_per_prenotazione > 1 ? "animali " : "animale "}
                            è di: € {summary.toFixed(2)}
                            {(parseFloat(summary.toFixed(2)) > 77.47) && <span> + € 2,00 di marca da bollo.</span>}
                        </p>

                        <div className="row">
                            <div className="col-md-6 my-2">
                                <FormUpload formik={props.formik} name="documento_di_pagamento"
                                            value={props.formik.values.documento_di_pagamento}
                                            error={props.formik.errors.documento_di_pagamento}
                                            type="file" label="Ricevuta pagamento (1)"></FormUpload>
                            </div>
                        </div>
                    </>}


                    {props.tipologia_animali === "animali_da_reddito" && <>
                        <hr/>

                        <h2 className="my-3 mt-5">
                            Note per il Distretto
                        </h2>

                        {formNotes(props.formik)}
                    </>}


                    <div style={containerActionsStyles}>
                        <hr/>
                        <div style={actionsStyles}>
                            <div>
                                <Button className="bg-white" variant="outline-primary"
                                        onClick={cancel}>Indietro</Button>
                            </div>
                            <div>
                                <Button variant="primary" type="submit"
                                        disabled={!props.formik.isValid || !props.formik.dirty}>Prossimo</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </Form>
        </Formik>
    );
};

export default Documents;
