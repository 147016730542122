import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { config } from '../config';
import {Logs, Place, Prestazione, Service, User} from '../assets/interface/interface'

interface SessionData {
    user_id: number;
    logged: boolean;
    role: "admin" | "veterinario";
    district_id: number;
}

export const apiSlice = createApi({
    reducerPath: 'api',
    baseQuery: fetchBaseQuery({
        baseUrl: config.apiUrl,
    }),
    endpoints: (builder) => ({
        getSession: builder.query<SessionData, void>({
            query: () => {
                return { url: `/front/get_session.php`, credentials: "include" };
            },
        }),
        getStrutture: builder.query<Place[], void>({
            query: () => '/front/get_struttura.php',
        }),
        getComuni: builder.query<Place[], any>({
            query: (district_id?: number) =>
                `/front/get_comuni.php${district_id ? `?district_id=${district_id}` : ''}`,
        }),
        getPrestazioni: builder.query<Prestazione[], {reddito: boolean, tipo: string}>({
            query: ({reddito, tipo} ) => `/front/get_prestazioni.php?reddito=${reddito}&tipo=${tipo}`,
        }),
        getEventsToAssign: builder.query<Service[], any>({
            query: (district_id: any) => `/front/get_events_to_assign.php?district_id=${district_id}`,
        }),
        getEventByVetId: builder.query<any[], {vet_id: any}>({
            query: ({ vet_id }) =>
                `/front/get_veterinary_bookings.php?vet_id=${vet_id}`,
        }),
        getVeterinaryDayBookings: builder.query<User, {vet_id: any, start_time: string }>({
            query: ({ vet_id, start_time }) =>
                `/front/get_veterinary_day_bookings.php?vet_id=${vet_id}&start_time=${start_time}`,
        }),
        getLogs: builder.query<Logs[], any>({
            query: (district_id: any) => `/front/get_logs.php?district_id=${district_id}`,
        }),
        getVeterinari: builder.query<User[], any>({
            query: (district_id: any) => `/front/get_veterinary.php?district_id=${district_id}`,
        }),
        createManualService: builder.mutation({
            query: (newPost) => ({
                url: '/front/insert_manual_booking.php',
                method: 'POST',
                body: newPost,
            }),
        }),
        createDistrictService: builder.mutation({
            query: (newPost) => ({
                url: '/front/insert_district_booking.php',
                method: 'POST',
                body: newPost,
            }),
        }),
        createAssignBooking: builder.mutation({
            query: (newPost) => ({
                url: '/front/assign_booking.php',
                method: 'POST',
                body: newPost,
            }),
        }),
        getAziendeByCode: builder.query<any[], {search: string}>({
            query: ({search}) =>
                `/front/get_aziende.php?search=${search}`,
        }),

    }),
});

export const {
    useGetSessionQuery,
    useGetStruttureQuery,
    useGetComuniQuery,
    useGetPrestazioniQuery,
    useGetEventsToAssignQuery,
    useGetEventByVetIdQuery,
    useGetVeterinaryDayBookingsQuery,
    useGetLogsQuery,
    useGetVeterinariQuery,
    useCreateManualServiceMutation,
    useCreateDistrictServiceMutation,
    useCreateAssignBookingMutation,
    useGetAziendeByCodeQuery,
} = apiSlice;
