import React, {useContext, useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleCheck} from "@fortawesome/free-regular-svg-icons";
import {faTriangleExclamation} from "@fortawesome/free-solid-svg-icons";
import {BookingBase, Prestazione, Place} from "../assets/interface/interface";
import MapSportello from "../components/MapSportello";
import axios from "axios";
import {format} from "date-fns";
import InfoSportelloDetail from '../components/InfoSportelloDetail';
import Loading from "../components/Loading";
import {config} from "../config";
import {ToastContext} from "../services/ToastService";
import {useGetComuniQuery, useGetPrestazioniQuery, useGetStruttureQuery} from "../services/apiSlice";

const BookingRequest = () => {
    // Ottieni l'ID della prenotazione dalla URL utilizzando useParams
    const { id } = useParams();
    const { animal_type } = useParams();

    const [book, setBook] = useState<BookingBase>();

    const navigate = useNavigate();
    const {showToast} = useContext(ToastContext)!;

    const { data: struttureData, error: errorStrutture,  } = useGetStruttureQuery();
    const { data: prestazioniData, error: prestazioniError } = useGetPrestazioniQuery({
        reddito: animal_type === "animali_da_reddito",
        tipo: "manuale"
    });
    const { data: comuniData, error: errorComuni} = useGetComuniQuery(null);

    const getBookById = async (id: string | undefined) => {
        if (!id) {
            showToast("danger", "Errore", "ID non valido.");
            return;
        }

        try {
            const response = await fetch(`${config.apiUrl}/front/get_event_by_order_id.php?order_id=${id}&reddito=${animal_type === "animali_da_reddito"}`);
            const data = await response.json();

            if (data) {
                setBook(data);
            } else {
                navigate("/");
            }
        } catch (error) {
            showToast("danger", "Errore", "Errore durante la richiesta della prenotazione.");
            console.error('Errore durante la richiesta API:', error);
        }
    };

    useEffect(() => {
        getBookById(id);
    }, [])

    if (book) {
        return (
            <div className="container">
                <div className="d-flex flex-row">
                    <div className="fs-3 text-primary" style={{ marginTop: "45px", marginRight: "19px" }}>
                        <FontAwesomeIcon icon={faCircleCheck} />
                    </div>
                    <h1>Richiesta prenotazione inserita: {book && prestazioniData && prestazioniData.filter(p => p.id === (animal_type == "animali_da_reddito" ? book?.tipo_prestazione : book?.tipo_prenotazione))[0]?.descrizione}</h1>
                </div>

                <div className="row">
                    <div className="col-md-9 mb-5">
                        {animal_type === "animali_da_affezione" && <p className="text-black ms-5">L'appuntamento è stato richiesto per
                            il <b>{book?.date_prenotazione && format(new Date(book.date_prenotazione), 'dd/MM/yyyy')} alle {book?.date_prenotazione && new Date(book.date_prenotazione).toLocaleTimeString([], {
                                hour: '2-digit',
                                minute: '2-digit',
                                hour12: false
                            })}</b>.</p>
                        }
                        <div className="bg-primary text-white p-5">
                            <div className="d-flex flex-row gap-3 align-items-center">
                                <div className="fs-3">
                                    <FontAwesomeIcon icon={faTriangleExclamation} />
                                </div>
                                <div className="fw-bold text-uppercase fs-20">
                                    ATTENZIONE
                                </div>
                            </div>
                            <div className="fs-20 mt-2">
                                <b>Conferma</b> l’appuntamento cliccando sul link che è stato inviato al tuo indirizzo mail: <b>{book?.email}</b>, se non confermi la prenotazione <b>entro 4 ore</b> la richiesta verrà annullata.
                            </div>
                        </div>

                        <p className="text-black mt-4 ms-5">Se non ti è arrivata la mail, controlla nello SPAM o chiama lo sportello che hai selezionato.</p>
                    </div>
                    <div className="col-md-3">
                        {animal_type === "animali_da_affezione" && struttureData && book && <MapSportello place={struttureData.filter(str => str.id === book.sportello)[0]} />}
                        {animal_type === "animali_da_reddito" && comuniData && book && <MapSportello place={comuniData.filter(str => str.id === book.comune)[0]} />}

                        {animal_type === "animali_da_affezione" && struttureData && book && <InfoSportelloDetail book={book} struttura={struttureData.filter(str => str.id === book.sportello)[0]} date={book.date_prenotazione} />}
                        {animal_type === "animali_da_reddito" && comuniData && book && <InfoSportelloDetail book={book} struttura={comuniData.filter(str => str.id === book.comune)[0]} />}
                    </div>
                </div>
            </div>
        );
    } else {
        return <Loading />;
    }
};

export default BookingRequest;
