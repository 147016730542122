import React, {useEffect, useState} from "react";
import Modal from "react-bootstrap/Modal";
import {Button, Spinner} from "react-bootstrap";
import {Service, User} from "../assets/interface/interface";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLocationDot} from "@fortawesome/free-solid-svg-icons";
import {faClock} from "@fortawesome/free-regular-svg-icons";
import {addMinutes, extractStartTime, formatTime, formatTimeRange} from "../assets/utilities/utilities";
import AvatarUser from "./AvatarUser";
import {format} from "date-fns";
import {useGetComuniQuery, useGetPrestazioniQuery} from "../services/apiSlice";

interface Props {
    modalShow: boolean;
    setModalShow(show: boolean): void;
    userSelected: User;
    selectedStartDate: Date;
    selectedEndDate: Date;
    serviceSelected: any;
    assignBooking(): void;
    isLoading: boolean;
    slot: number;
}

const ModalSummary = (props: Props) => {

    const [appointments, setAppointments] = useState<any[]>();
    const { data: prestazioniData, error: prestazioniError } = useGetPrestazioniQuery(
        {
            reddito: true,
            tipo: "sportello"
        }
    );

    const {data: comuniData,
        error: errorComuni} = useGetComuniQuery(null);

    const containerActionsStyles = {
        marginTop: "40px"
    };

    const actionsStyles = {
        display: "flex",
        flexDirection: "row" as const,
        alignItems: "center",
        justifyContent: "end",
        gap: "15px",
    };

    useEffect(() => {

        if (!props.userSelected.appointments) {
            return;
        }

        const newAppointment = {
            id: 0,
            comune: props.serviceSelected.comune,
            booking_date_time_start: props.selectedStartDate,
            booking_date_time_end: props.selectedEndDate,
            is_manual: false,
        };

        const newAppointments = [
            ...(props.userSelected?.appointments?.filter(x => {
                // Verifica che booking_date_time_start esista
                if (!x.booking_date_time_start) return false;

                const selectedDate = new Date(props.selectedStartDate);
                const appointmentDate = new Date(x.booking_date_time_start);

                // Controlla che la data coincida, ma l'ora sia diversa e i comuni siano diversi
                return (
                    appointmentDate.toDateString() === selectedDate.toDateString() &&
                    appointmentDate.getTime() !== selectedDate.getTime()
                );
            }) || []), // Fallback a un array vuoto se appointments non esiste
            newAppointment
        ];



        newAppointments.sort((a: any, b: any) => {
            const timeA = new Date(a.booking_date_time_start).getTime();
            const timeB = new Date(b.booking_date_time_start).getTime();
            return timeA - timeB;
        });


        setAppointments(newAppointments);

    }, [props]);

    // @ts-ignore
    return (
        <Modal
            size="lg"
            centered
            show={props.modalShow}
            onHide={() => props.setModalShow(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
        >
            <Modal.Header className={`bg-primary ${props.serviceSelected?.stato || ''}`}>
                <Modal.Title id="example-custom-modal-styling-title" >
                    <h2 className="text-white title-modal">
                        {
                            (() => {
                                switch (props.serviceSelected?.stato) {
                                    case "accepted":
                                        return "Prenotazione Accettata";
                                    case "pending":
                                        return "Prenotazione in attesa";
                                    case "rejected":
                                        return "Prenotazione rifiutata";
                                    default:
                                        return "Conferma Programmazione";
                                }
                            })()
                        }
                    </h2>

                </Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <div className="pt-4">
                    Conferma Prestazione per: <b className="text-black">{
                        prestazioniData && prestazioniData.filter(x => x.id == props.serviceSelected.tipo_prestazione)[0]?.descrizione
                    }</b>

                    <div className="d-flex flex-row gap-3 align-items-center my-2">
                        <div className={`fs-5 text-primary ${props.serviceSelected?.stato || ''}`}>
                            <FontAwesomeIcon icon={faLocationDot} />
                        </div>
                        <div>{comuniData && comuniData.filter(x => x.id == props.serviceSelected.comune)[0].descrizione}</div>
                    </div>

                    <div className="d-flex flex-row gap-3 align-items-center">
                        <div className={`fs-5 text-primary ${props.serviceSelected?.stato || ''}`}>
                            <FontAwesomeIcon icon={faClock} />
                        </div>
                        <div>
                            {formatTimeRange(props.selectedStartDate, props.selectedEndDate)}
                        </div>
                    </div>

                    <hr/>

                    <AvatarUser user={props.userSelected}></AvatarUser>

                    <h2 className="my-3"> { format(new Date(props.selectedStartDate), 'dd/MM/yyyy') }</h2>

                    <div className="my-3">

                        {appointments && appointments.map((appointment) => (
                            <div key={appointment.id} className={`card-appointment border-${appointment.is_manual ? "blu" : "primary"} ${appointment.id === 0 && "new-appointment"} ${appointment.stato == "3" && "pending"} ${props.serviceSelected.stato}`}>
                                <h2 className="m-0">{new Date(appointment.booking_date_time_start).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false })} - {new Date(appointment.booking_date_time_end).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false })}</h2>
                                <p className="m-0">{comuniData && comuniData.filter(x => x.id == appointment.comune)[0].descrizione}</p>
                            </div>
                        ))}

                    </div>

                    <div style={containerActionsStyles}>
                        <hr/>
                        <div style={actionsStyles}>
                            <div>
                                <Button className="bg-white" variant="outline-primary" onClick={() => props.setModalShow(false)}>Indietro</Button>
                            </div>
                            {props.serviceSelected.stato === "to_be_assigned" || (props.serviceSelected.stato == 6 || props.serviceSelected.stato == 1) && <div>
                                <Button variant="primary"
                                        disabled={props.isLoading}
                                        onClick={props.assignBooking}>
                                    {props.isLoading && <span className="me-2">
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                            variant="light"
                                        />
                                        <span className="visually-hidden">Loading...</span>
                                    </span>}
                                    Programma</Button>
                            </div>}
                        </div>
                    </div>
                </div>

            </Modal.Body>
        </Modal>
    );
}

export default ModalSummary;
