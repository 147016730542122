import React, {useEffect, useState} from "react";
import AvatarUser from "./AvatarUser";
import { format} from "date-fns";
import {Button} from "react-bootstrap";
import {User} from "../assets/interface/interface";
import {addMinutes, extractStartTime} from "../assets/utilities/utilities";
import {useGetComuniQuery} from "../services/apiSlice";

interface Props {
    user: User;
    selectedStartDate: Date;
    selectedEndDate: Date;
    comuneId: string;
    confirmAppointment(user: User): void;
}
const UserTimeline = (props: Props) => {

    const {
        data: comuniData,
        error: errorComuni} = useGetComuniQuery(null);

    const containerActionsStyles = {
        marginTop: "20px"
    };

    const actionsStyles = {
        display: "flex",
        flexDirection: "row" as const,
        alignItems: "center",
        justifyContent: "end",
        gap: "15px",
    };


    const [appointments, setAppointments] = useState<any[]>();

    useEffect(() => {

        if (!props.user.appointments) {
            return;
        }

        // Crea il nuovo appuntamento
        const newAppointment = {
            id: 0,
            comune: props.comuneId,
            booking_date_time_start: props.selectedStartDate,
            booking_date_time_end: props.selectedEndDate,
            is_manual: false,
        };

        const newAppointments = [
            ...props.user.appointments.filter(x => {
                return x.booking_date_time_start && new Date(x.booking_date_time_start).toDateString() == new Date(props.selectedStartDate).toDateString();
            }),
            newAppointment
        ];

        newAppointments.sort((a: any, b: any) => {
            const timeA = new Date(a.booking_date_time_start).getTime();
            const timeB = new Date(b.booking_date_time_start).getTime();
            return timeA - timeB;
        });
        setAppointments(newAppointments);

    }, [props.selectedStartDate]);

    return (
        <div>
            <AvatarUser user={props.user}></AvatarUser>

            <div className="bg-white p-3 my-3">
                <h2 className="my-3"> { format(new Date(props.selectedStartDate), 'dd/MM/yyyy') }</h2>

                <div className="my-3">

                    {appointments && appointments.map((appointment) => (
                        <div key={appointment.id} className={`card-appointment border-${appointment.is_manual ? "blu" : "primary"} ${appointment.id === 0 && "new-appointment"} ${appointment.stato == "3" && "pending"}`}
                            title={appointment.id === 0 ? "Evento da confermare" : (appointment.stato == "3" ? "Evento in attesa di conferma" : (appointment.is_manual ? "Evento inserito manualmente" : "Evento assegnato dal Direttore")) }>
                            <h2 className="m-0">{new Date(appointment.booking_date_time_start).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false })} - {new Date(appointment.booking_date_time_end).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false })}</h2>
                            <p className="m-0">{comuniData && comuniData.filter(x => x.id == appointment.comune)[0].descrizione}</p>
                        </div>
                    ))}

                </div>

            </div>

            <div style={containerActionsStyles}>
                <div style={actionsStyles}>
                    <div>
                        <Button variant="primary" onClick={() => props.confirmAppointment(props.user)}>Conferma</Button>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default UserTimeline;
