import React, {useEffect, useRef, useState} from "react";
import Form from "react-bootstrap/Form";
import {Formik} from "formik";
import {Button} from "react-bootstrap";
import FormSelect from "./FormSelect";
import 'leaflet/dist/leaflet.css';
import {MapContainer, Marker, TileLayer} from "react-leaflet";
import 'leaflet/dist/leaflet.css';
import L, {LatLngExpression} from 'leaflet';
import { Map as LeafletMap } from 'leaflet';
import {convertStringToNumberArray} from "../assets/utilities/utilities";
import {
    useGetAziendeByCodeQuery,
    useGetComuniQuery, useGetLogsQuery,
    useGetPrestazioniQuery,
    useGetStruttureQuery
} from "../services/apiSlice";
import {Place} from "../assets/interface/interface";
import FormText from "./FormText";
import FormAutocomplete from "./FormAutocomplete";
import {tariffeMacellazione} from "../assets/utilities/variables";

interface Props {
    formik: any;
    tipologia_animali: string;
    goToBackTab(): void;
}

const BookingType: React.FC<Props> = (props: Props) => {

    const [isShowComune, setIsShowComune] = useState<boolean>(false);
    const [isShowSportello, setIsShowSportello] = useState<boolean>(false);

    const mapRef = useRef<LeafletMap | null>(null);

    const initialPosition: any = [45.840748, 8.912932]; // Inserisci le coordinate iniziali qui
    const initialZoom = 9;

    const [struttura, setStruttura] = useState<Place>();

    const { data: comuniData, error: errorComuni } = useGetComuniQuery(null);
    const { data: struttureData, error: errorStrutture,  } = useGetStruttureQuery();
    const { data: prestazioniData, error: prestazioniError } = useGetPrestazioniQuery({
        reddito: props.tipologia_animali === "animali_da_reddito",
        tipo: "sportello"
    });
    
    const [codeSearch, setCodeSearch] = useState<string>("");

    const { data: aziendeByCodeData, error: errorAziendeByCode, refetch: refetchAziendeByCode} = useGetAziendeByCodeQuery({search: codeSearch},
        {
            skip: !codeSearch || codeSearch?.length < 2
        });

    const [coordinateAllevamento, setCoordinateAllevamento] = useState<string>();

    useEffect(() => {
        if (codeSearch?.length < 3) {
            return;
        }
        refetchAziendeByCode();
    }, [codeSearch]);

    useEffect(() => {
        setIsShowComune(Boolean(props.tipologia_animali === "animali_da_reddito"));
        setIsShowSportello(props.tipologia_animali === "animali_da_affezione");
    }, [props.tipologia_animali])

    // Definisci l'icona personalizzata
    const customIcon = new L.Icon({
        iconUrl: "/images/maps-and-flags.png", // Percorso dell'immagine personalizzata
        iconSize: [32, 32], // Dimensioni dell'icona [larghezza, altezza]
        iconAnchor: [16, 32], // Punto in cui l'icona deve essere posizionata rispetto alla sua posizione
        popupAnchor: [0, -32], // Punto in cui deve essere posizionato il popup rispetto alla sua posizione
    });

    const containerActionsStyles = {
        marginTop: "40px"
    };

    const actionsStyles = {
        display: "flex",
        flexDirection: "row" as const,
        alignItems: "center",
        justifyContent: "end",
        gap: "15px",
    };

    const updateMapPositionWithTransition = (position: LatLngExpression, zoom: number) => {
        if (mapRef.current) {
            mapRef.current.flyTo(position, zoom, {
                duration: 1.5 // Durata della transizione in secondi
            });
        }
    };

    const handleMarkerSportelloClick = (pin: Place) => {
        if (!pin.id) {
            return;
        }
        updateMapPositionWithTransition(convertStringToNumberArray(pin.coordinate), 15);
        props.formik.setFieldValue("sportello", {value: pin.id.toString(), label: struttureData && struttureData.filter(str => str.id === pin.id)[0].descrizione});
    };

    const onChangeSportello = (change: any) => {
        if (!struttureData) {
            return;
        }
        const strutturaSelected = struttureData.filter(str => str.id === change.value)[0];
        setStruttura(strutturaSelected);
        updateMapPositionWithTransition(convertStringToNumberArray(strutturaSelected.coordinate), 15);
        props.formik.setFieldValue("sportello", change);
    }

    useEffect(() => {
        if (props.formik.values?.indirizzo && props.formik.values?.comune) {
            setCoordinateAllevamento(undefined);
            returnCoordinateAllevamento(props.formik.values?.indirizzo, props.formik.values?.comune);
        }
    }, []);

    const onChangeCodiceAllevamento = (change: any) => {

        props.formik.setFieldValue("azienda", change);

        setTimeout(() => {
            props.formik.validateForm();
        }, 100);
    }

    const returnCoordinateAllevamento = async (indirizzo: string, comune: string) => {

        const indirizzoFull = indirizzo + " " + comune;
        const indirizzoEncoded = indirizzoFull.replace(/ /g, '+');

        //Esegui la query per ottenere le coordinate
        fetch('https://nominatim.openstreetmap.org/search?q=' + indirizzoEncoded + '&format=json')
            .then(response => response.json())
            .then(data => {
                if (data && data[0] && data[0].lat && data[0].lon) {
                    const newCoordinate = data[0].lat + "," + data[0].lon;
                    if (coordinateAllevamento !== newCoordinate) {
                        setCoordinateAllevamento(newCoordinate);
                        updateMapPositionWithTransition(convertStringToNumberArray(newCoordinate), 15);
                    }
                }
            }, error => {
                console.error(error);
            });

    }

    const onKeyDown = (change: any) => {
        setCodeSearch(change.nativeEvent.srcElement.defaultValue);
    }

    const cancel = () => {
        props.goToBackTab();
    };

    return (
        <Formik initialValues={props.formik.initialValues}  onSubmit={props.formik.handleSubmit}>
            <Form onSubmit={props.formik.handleSubmit}>
                <div className="p-4 rounded-lg mb-100">
                    <h2 className="my-3">
                        Tipologia
                    </h2>
                    <div className="pb-4">
                        <div className="my-3">
                            {prestazioniData && <FormSelect name="tipo_prenotazione" formik={props.formik} label="Tipologia Prestazione"
                                         value={props.formik.values.tipo_prenotazione}
                                         options={prestazioniData}></FormSelect>}
                        </div>

                        { props.formik.values?.tipo_prenotazione?.value &&  <>

                            {prestazioniData && prestazioniData.filter(prestazione => prestazione.id === props.formik.values?.tipo_prenotazione?.value)[0]?.documenti.length > 0 && <div className="mt-1">
                                Prima di procedere ricordati di avere questi documenti:
                                <br/>
                                <br/>
                                <ul>
                                    {prestazioniData && prestazioniData.filter(prestazione => prestazione.id === props.formik.values?.tipo_prenotazione?.value)[0]?.documenti.map((doc, index) => (
                                        <li key={index}>{doc}</li>))}
                                </ul>
                            </div>}

                            {props.tipologia_animali === "animali_da_affezione" && <div>
                                Il costo unitario della prestazione è di:

                                {
                                    prestazioniData && prestazioniData
                                        .filter(prestazione => prestazione.id === props.formik.values?.tipo_prenotazione?.value)
                                        .map(prestazione => (
                                            prestazione.tariffe?.length === 1 && (
                                                <React.Fragment key={prestazione.id}>
                                                    <span> € {prestazione.tariffe[0].prezzo}. </span>
                                                </React.Fragment>
                                            )
                                        ))
                                }


                                {
                                    prestazioniData && prestazioniData
                                        .filter(prestazione => prestazione.id === props.formik.values?.tipo_prenotazione?.value)
                                        .map(prestazione => (
                                            prestazione.tariffe?.length > 1 && (
                                                <React.Fragment key={prestazione.id}>
                                                    <ul className="mb-0">
                                                        {prestazione.tariffe.map((tariffa, index) => (
                                                            <li key={"tariffa" + index}>€ {tariffa.prezzo}: {tariffa.descrizione_tariffa}</li>
                                                        ))}
                                                    </ul>
                                                </React.Fragment>
                                            )
                                        ))
                                }

                                In caso di prenotazione per più animali il costo della prestazione va moltiplicato per
                                il numero di animali. Se il totale è uguale o superiore a 77,47€ vanno aggiunti 2 € del
                                bollo.



                            </div>}


                            {(props.tipologia_animali === "animali_da_affezione" || (props.tipologia_animali === "animali_da_reddito" && props.formik.values?.tipo_prenotazione?.value === "25")) && <>
                                <br/>
                                <br/>

                                * Per effettuare il pagamento accedi al sito web di <a
                                href="https://pagamentinlombardia.servizirl.it/pa/home.html" target="_blank"> Regione
                                Lombardia</a> nella sezione <b>Cerca Ente</b> seleziona <a>ATS dell'INSUBRIA</a>, scegli
                                tra le tipologie di servizio la voce <b>Servizio Veterinario</b> e procedi con il
                                pagamento della specifica tariffa.
                            </>}


                            {(props.formik.values?.tipo_prenotazione?.value === "1" || props.formik.values?.tipo_prenotazione?.value === "3") && <>
                                <br/>
                                <br/>

                                ** Informarsi presso Ambasciata/Consolato/sito ufficiale.
                            </>}

                            {(props.formik.values?.tipo_prenotazione?.value === "1") && <>
                                <br/>
                                <br/>
                                *** Scarica qui il documento per la <a target="_blank" href="/allegati/documenti_a60/Rich_Passaporto_2022.pdf">Richiesta di Rilascio Passaporto</a>.
                            </>}

                            {(props.formik.values?.tipo_prenotazione?.value === "2") && <>
                                <br/>
                                <br/>

                                ** Scarica qui il documento per la <a target="_blank" href="/allegati/documenti_a60/Modulo_iscrizione_anagrafe_canina_22.pdf">Dichiarazione cessione animale</a>.
                            </>}

                            {(props.formik.values?.tipo_prenotazione?.value === "3") && <>
                                <br/>
                                <br/>

                                *** Scarica qui il documento per il <a target="_blank" href="/allegati/documenti_a13_05/Rich_CertificatoEspatrio_2022.pdf">Certificato di Espatrio</a>.
                            </>}

                            {(props.formik.values?.tipo_prenotazione?.value === "3") && <>
                                <br/>
                                <br/>

                                **** Da presentare al momento del ritiro del certificato, rispettando il termine delle 48 ore.
                            </>}

                            <br/>
                            <br/>

                            {props.tipologia_animali === "animali_da_affezione" && <u><i>Presentarsi per il ritiro di persona senza animale o con delega del proprietario allegando fotocopia di un documento d’Identità valido.</i></u>}

                            {props.tipologia_animali === "animali_da_reddito" && props.formik.values?.tipo_prenotazione?.value === "25" && <>
                                <table className="table table-bordered table-striped rounded mt-3 overflow-hidden">
                                    <thead>
                                    <tr>
                                        <th className="bg-grey text-white">Numero Suini</th>
                                        <th className="bg-grey text-white">Codice e Quantità</th>
                                        <th className="bg-grey text-white">Totale (€)</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td>1 SUINO</td>
                                        <td>A.21.01 (1) + A.21.01T (1)</td>
                                        <td>{tariffeMacellazione[1]}</td>
                                    </tr>
                                    <tr>
                                        <td>2 SUINI</td>
                                        <td>A.21.01 (1) + A.21.02 (1) + A.21.01T (2)</td>
                                        <td>{tariffeMacellazione[2]}</td>
                                    </tr>
                                    <tr>
                                        <td>3 SUINI</td>
                                        <td>A.21.01 (1) + A.21.02 (2) + A.21.01T (3)</td>
                                        <td>{tariffeMacellazione[3]}</td>
                                    </tr>
                                    <tr>
                                        <td>4 SUINI</td>
                                        <td>A.21.01 (1) + A.21.02 (3) + A.21.01T (4)</td>
                                        <td>{tariffeMacellazione[4]}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </>}

                        </>}

                    </div>

                    <hr />


                    <h2 className="my-3 pt-3">
                        Posizione
                    </h2>
                    <div className="row">
                        <div className="col-md-6 my-3">
                            <div style={{ width: '100%', height: '400px' }}>
                                <MapContainer ref={mapRef} center={initialPosition} zoom={initialZoom}
                                               style={{width: "100%", height: '100%', zIndex: 0}}>
                                    <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"/>

                                    {isShowSportello && struttureData && struttureData.map((pin) => (
                                        <Marker
                                            key={pin.id}
                                            position={convertStringToNumberArray(pin.coordinate)}
                                            icon={customIcon}
                                            eventHandlers={{
                                                click: () => handleMarkerSportelloClick(pin)
                                            }}
                                        >
                                        </Marker>
                                    ))}

                                    {coordinateAllevamento && <Marker
                                        position={convertStringToNumberArray(coordinateAllevamento)}
                                        icon={customIcon}></Marker>}

                                </MapContainer>
                            </div>
                        </div>


                        <div className="col-md-6 my-3">

                            {isShowComune ? (
                                <div className="d-flex flex-column gap-3">
                                    <FormAutocomplete name="azienda"
                                                      formik={props.formik}
                                                      label="Codice Allevamento"
                                                      value={props.formik.values.azienda}
                                                      options={aziendeByCodeData ? aziendeByCodeData : []}
                                                      labelKey="cod_azienda"
                                                      optionHtml={(option: any, props: any) => (
                                                          <div className="small">
                                                              <div className="fw-bold">{option.cod_azienda} / {option.sottocod_azienda}</div>
                                                              <div>{option.ragione}</div>
                                                          </div>
                                                      )}
                                                      onKeyDown={onKeyDown}
                                                      onChange={onChangeCodiceAllevamento}></FormAutocomplete>

                                    {props.formik.values.azienda && props.formik.values.azienda[0] && <div className="mt-3">
                                        <div className="fw-bold">{props.formik.values.azienda[0].ragione}</div>
                                        <p>{props.formik.values.azienda[0].cod_azienda} / {props.formik.values.azienda[0].sottocod_azienda}</p>
                                        <p>
                                            {props.formik.values.azienda[0].indirizzo}, ({props.formik.values.azienda[0].comune})
                                        </p>
                                    </div>}
                                </div>
                            ) : (
                                struttureData ? (
                                    <FormSelect
                                        name="sportello"
                                        formik={props.formik}
                                        label="Sportello"
                                        value={props.formik.values.sportello}
                                        options={struttureData}
                                        onChange={onChangeSportello}
                                    />
                                ) : null
                            )}


                            {isShowSportello && struttura && <div>
                                <br/><br/>
                                <a href="mailto:">{ struttura.email}</a>
                                <p>
                                    { struttura.indirizzo}<br/>
                                    Tel. <a href={ "tel:+39" + struttura.telefono_1}>{ struttura.telefono_1}</a> {struttura.telefono_2 && <span>- <a href={ "tel:+39" + struttura.telefono_2}>{ struttura.telefono_2}</a></span>}
                                </p>
                            </div>}

                        </div>

                    </div>

                    <div style={containerActionsStyles}>
                        <hr/>
                        <div style={actionsStyles}>
                            <div>
                                <Button className="bg-white" variant="outline-primary"
                                        onClick={cancel}>Indietro</Button>
                            </div>
                            <div>
                                <Button variant="primary" type="submit" disabled={!props.formik.isValid || !props.formik.dirty}>Prossimo</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </Form>
        </Formik>
    );
};

export default BookingType;
