import React, {useEffect, useState} from "react";
import Modal from "react-bootstrap/Modal";
import {Button, Form, Spinner} from "react-bootstrap";
import FormSelect from "./FormSelect";
import { Formik } from "formik";
import FormText from "./FormText";
import FormUpload from "./FormUpload";
import {useGetAziendeByCodeQuery, useGetComuniQuery, useGetPrestazioniQuery} from "../services/apiSlice";
import FormAutocomplete from "./FormAutocomplete";
import {returnCoordinateAllevamento} from "../assets/utilities/utilities";
import {formAnimaliPerPrenotazione, formMail, formNotes, formTelefono} from "../assets/utilities/variables";
import FormDatePicker from "./FormDatePicker";

interface Props {
    formik: any;
    modalShow: boolean;
    setModalShow(show: boolean): void;
    districtId: number;
    isLoading: boolean;
}

const ModalNewService = (props: Props) => {

    const { data: prestazioniData, error: prestazioniError } = useGetPrestazioniQuery({
            reddito: true,
            tipo: "sportello"
        });

    const [codeSearch, setCodeSearch] = useState<string>("");

    const { data: aziendeByCodeData, error: errorAziendeByCode, refetch: refetchAziendeByCode} = useGetAziendeByCodeQuery({search: codeSearch},
        {
            skip: !codeSearch || codeSearch?.length < 2
        });

    const containerActionsStyles = {
        marginTop: "40px"
    };

    const actionsStyles = {
        display: "flex",
        flexDirection: "row" as const,
        alignItems: "center",
        justifyContent: "end",
        gap: "15px",
    };

    const onKeyDown = (change: any) => {
        setCodeSearch(change.nativeEvent.srcElement.defaultValue);
    }

    const onChangeCodiceAllevamento = (change: any) => {
        props.formik.setFieldValue("azienda", change);
        setTimeout(() => {
            props.formik.validateForm();
        }, 100);
    }


    return (
        <Modal
            size="lg"
            centered
            show={props.modalShow}
            onHide={() => props.setModalShow(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
        >
            <Modal.Header className="bg-primary">
                <Modal.Title id="example-custom-modal-styling-title">
                    <h2 className="text-white title-modal">
                        Inserimento prestazione manuale
                    </h2>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <Formik initialValues={props.formik.initialValues}  onSubmit={props.formik.handleSubmit}>
                    <Form onSubmit={props.formik.handleSubmit}>
                        <h2 className="my-3">
                            Prestazione
                        </h2>

                        <div className="row">
                            {prestazioniData && <div className="col-md-6 my-2">
                                <FormSelect name="tipo_prestazione" formik={props.formik}
                                            label="Tipologia Prestazione"
                                            value={props.formik.values.tipo_prestazione}
                                            options={prestazioniData}></FormSelect>
                            </div>}

                            <div className="col-md-6 my-2">
                                <div className="d-flex flex-column gap-3">
                                    <FormAutocomplete name="azienda"
                                                      formik={props.formik}
                                                      label="Codice Allevamento"
                                                      value={props.formik.values.azienda}
                                                      options={aziendeByCodeData ?? []}
                                                      labelKey="cod_azienda"
                                                      optionHtml={(option: any, props: any) => (
                                                          <div className="small">
                                                              <div
                                                                  className="fw-bold">{option.cod_azienda} / {option.sottocod_azienda}</div>
                                                              <div>{option.ragione}</div>
                                                          </div>
                                                      )}
                                                      onKeyDown={onKeyDown}
                                                      onChange={onChangeCodiceAllevamento}></FormAutocomplete>

                                    {props.formik.values.azienda && props.formik.values.azienda[0] &&
                                        <div className="mt-3">
                                            <div className="fw-bold">{props.formik.values.azienda[0].ragione}</div>
                                            <p>{props.formik.values.azienda[0].cod_azienda} / {props.formik.values.azienda[0].sottocod_azienda}</p>
                                            <p>
                                                {props.formik.values.azienda[0].indirizzo},
                                                ({props.formik.values.azienda[0].comune})
                                            </p>
                                        </div>}
                                </div>
                            </div>

                        </div>

                        <h2 className="mt-4 mb-3">
                            Dati Utente
                        </h2>

                        <div className="row">
                            <div className="col-md-6 my-2">
                                {formMail(props.formik)}
                            </div>
                            <div className="col-md-6 my-2">
                                {formTelefono(props.formik)}
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6">
                                {formNotes(props.formik)}
                            </div>
                            <div className="col-md-6">
                                {props.formik.values?.tipo_prestazione?.value && formAnimaliPerPrenotazione(props.formik, props.formik.values.tipo_prestazione.value)}
                            </div>
                        </div>

                        <div style={containerActionsStyles}>
                            <hr/>
                            <div style={actionsStyles}>
                                <div>
                                    <Button className="bg-white" variant="outline-primary"
                                            onClick={() => props.setModalShow(false)}>Indietro</Button>
                                </div>
                                <div>

                                    <Button variant="primary" type="submit"
                                            disabled={!props.formik.isValid || !props.formik.dirty}>
                                        {props.isLoading && <span className="me-2">
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                            variant="light"
                                        />
                                        <span className="visually-hidden">Loading...</span>
                                    </span>}
                                        Crea Prestazione
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Form>
                </Formik>

            </Modal.Body>
        </Modal>
    );
}

export default ModalNewService;
