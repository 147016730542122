
import 'react-bootstrap-typeahead/css/Typeahead.css';
import React, {useState} from "react";
import Form from 'react-bootstrap/Form';
import {Typeahead} from "react-bootstrap-typeahead";
import {FormikProps} from "formik";
import {Option} from "../assets/interface/interface";

interface Props {
    name: string;
    formik: FormikProps<any>;
    label: string;
    value: any;
    labelKey: string;
    placeholder?: string;
    disabled?: boolean;
    note?: string;
    onChange: (newValue: any) => void;
    onKeyDown: (keyDownValue: any) => void;
    optionHtml: (option: any, props: any) => JSX.Element;
    options: any[];
}

const FormAutocomplete: React.FC<Props> = (props: Props) => {

    return (
        <div>
            <Form.Group>
                <Form.Label>{props.label}</Form.Label>
                <Typeahead
                    id={"id_" + props.labelKey}
                    labelKey={props.labelKey}
                    minLength={3}
                    onChange={props.onChange}
                    options={props.options}
                    placeholder={props.placeholder ?? '-'}
                    selected={props.value}
                    onKeyDown={props.onKeyDown}
                    renderMenuItemChildren={props.optionHtml}
                    emptyLabel={"Nessun risultato"}
                />
            </Form.Group>
        </div>
    );
};

export default FormAutocomplete;
